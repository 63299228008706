<script setup>
import { store, state } from './store/store.js';
import HamburgerMenu from './components/HamburgerMenu.vue';
</script>

<template>
    <div id="app-container">
        <div class="indeterminate-progress-bar" v-if="state.isAPIProcessing">
            <div class="indeterminate-progress-bar__progress"></div>
        </div>

        <div id="header-background" v-if="!routesWithoutHeader.includes(currentRouteName())"></div>
        <div id="navbar-one-background" v-if="['Campaigns', 'Invoices', 'Contract'].includes($route.name)"></div>
        <div id="navbar-two-background" v-else-if="$route.name == 'Campaign'"></div>

        <div id="header-container" v-if="!routesWithoutHeader.includes(currentRouteName())">
            <img id="header-logo" alt="Vocal logo" src="@/assets/logo.png">
            <div id="info">
                <span style="font-size: 13px;">Company</span>
                <span style="font-size: 14px; font-weight: 700;">{{ state.user?.user.name }}</span>
            </div>

            <div class="select-container mx-4">
                <select v-model="state.selectedCollaboration" @change="$router.push({path: '/campaigns'})" id="collaboration-select" name="collaboration-select">
                    <!-- <option value=""></option> -->
                    <option v-for="collaboration in state.collaborations" :key="collaboration.rid" :value="collaboration">{{ collaboration[55].value }}</option>
                </select>
            </div>

            <div class="icon-label-text-container mx-12" v-if="state.selectedCollaboration?.[59]?.value">
                <img class="" alt="link icon" src="@/assets/link.svg">
                <div class="text-section" style="width: 220px;">
                    <span class="title">Standing Call Agenda</span>
                    <a class="link" :href="state.selectedCollaboration?.[59]?.value">{{ state.selectedCollaboration?.[59]?.value }}</a>
                </div>
            </div>

            <div id="hamburger-menu-button-container">
                <!-- <img class="nav-btn" alt="hamburger menu" src="@/assets/hamburger.svg" @click="state.hamburgerMenu.toggle()"> -->
                <img class="" alt="hamburger menu" src="@/assets/hamburger.svg" @click="state.hamburgerMenu.toggle()">
            </div>
        </div>

        <div id="navbar" v-if="routesWithNav.includes(currentRouteName())">
            <div id="nav-btn-container">
                <router-link class="nav-btn" to="/campaigns">
                    Campaigns
                </router-link>
                <router-link class="nav-btn" to="/invoices">
                    Invoices
                </router-link>
                <router-link class="nav-btn" to="/contract">
                    Contract
                </router-link>
            </div>
        </div>

        <router-view class="app-body" />

        <HamburgerMenu v-if="state.hamburgerMenu.display"></HamburgerMenu>
    </div>
</template>

<script>
export default {
    data() {
        return {
            routesWithNav: ['Campaigns', 'Contract', 'Invoices'],
            routesWithoutHeader: ['Login', 'Signup', 'ChangePassword', 'ResetPassword', 'ForgotPassword']
        }
    },
    async mounted() {
        if (!state.user) {
            // this.$router.push('/login');
        } else {
            // fetch data
            await state.refreshData();
            // console.log('refreshData', state);

            // Select first collaboration
            if (state.collaborations.length > 0) {
                state.selectedCollaboration = state.collaborations[0];
            }
        }
    },
    methods: {
        currentRouteName() {
            return this.$route.name;
        }
    }
}
</script>

<style>
body {
    margin: 0;
    padding: 0;
}

#app {
    font-family: Avenir, Helvetica, Arial, sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    text-align: center;

    color: var(--text-default-color);
    background-color: #F9F9F9;

    display: flex;
    justify-content: center;
}

.icon-label-text-container {
    display: flex;
    /* flex-direction: column; */
    align-items: flex-start;
    justify-content: center;

    min-height: 32px;
    
}

.icon-label-text-container .text-section {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    /* justify-content: center; */
    margin-left: 10px;

    max-width: 250px;


}

.icon-label-text-container .text-section .title {
    font-size: 13px;
    font-weight: 400;
}

.icon-label-text-container .text-section .text {
    font-size: 12px;
    max-width: 200px;
}

.icon-label-text-container .text-section .link {
    font-size: 12px;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    max-width: 225px;
    text-decoration: underline;
    color: blue;

    cursor: pointer;
}

:root {
    --text-default-color: #231F20;
    --button-yellow: #F9D54A;

}

.wt-400 {
    font-weight: 400 !important;
}

.wt-500 {
    font-weight: 500 !important;
}

.wt-600 {
    font-weight: 600 !important;
}

.wt-700 {
    font-weight: 700 !important;
}

.mx-4 {
    margin-left: 4px;
    margin-right: 4px;
}

.mx-6 {
    margin-left: 6px;
    margin-right: 6px;
}

.mx-8 {
    margin-left: 8px;
    margin-right: 8px;
}

.mx-12 {
    margin-left: 12px;
    margin-right: 12px;
}

input:focus::placeholder {
    color: transparent;
}

input::placeholder {
    user-select: none;
}

select {
    border: 2px solid var(--text-default-color);
    height: 56px;
    border-radius: 24px;
    padding: 12px 16px;

    font-weight: 700;
    font-size: 16px;
    position: relative;
}

#collaboration-select {
    width: 256px;
}

#app-container {
    height: 100vh;
    width: 1280px;

    display: flex;
    flex-direction: column;

    /* make background an image */
/* 
    background-image: url("@/assets/background-gradient.png");
    background-image: url("@/assets/background-gradient-OLD.jpg");
    background-repeat: no-repeat;
    background-position: right 0px bottom 0px; */

    /* background-color: #F9F9F9; */
}

.app-body {
    flex-grow: 1;
    /* background-color: red; */

}

#header-background {
    position: absolute;
    height: 72px;
    background: white;
    width: 100dvw;
    left: 0;
    right: 0;
}

#navbar-one-background, #navbar-two-background{
    margin-top: 72px;
    position: absolute;
    height: 72px;
    background: #BFBDC1;
    border-bottom: 2px solid #102A18;
    width: 100dvw;
    left: 0;
    right: 0;
}

#navbar-two-background {
    height: 144px;
}

#header-container,
#navbar {
    height: 72px;
    min-height: 72px;
    display: flex;
    align-items: center;
    padding: 0 24px;

    font-weight: 500;

    z-index: 5;
}

#header-container {
    background-color: white;
}

#header-logo {
    max-height: 72px;
    /* margin-right: auto; */
}

#navbar {
    background-color: #BFBDC1;
    border-bottom: 2px solid #102A18;
    font-size: 16px;
    z-index: 5;
}

#nav-btn-container {
    display: flex;
    align-items: center;
    border: 2px solid var(--text-default-color);
    border-radius: 28px;
    height: 52px;
    background: white;
}

/* animate download-btn on hover to animate 10 pixels up and left to look like a button raising  */
.btn-animate-hover:hover {
  transform: translate(-2px, -2px);
  transition: transform 0.2s ease-in-out;

  box-shadow: 4px 4px 0px 0px black;
}

.btn-animate-hover:not(:hover) {
  transform: translate(2px, 2px);
  transition: transform 0.2s ease-in-out;

  /* box-shadow: 4px 4px 0px 0px black; */
}


.nav-btn {
    /* height: 44px; */
    padding: 13px 11px;
    margin: 0 6px;
    border-radius: 28px;
    background-color: white;
    color: var(--text-default-color);

    /* text-transform: uppercase; */
    text-decoration: none;
    -webkit-user-drag: none;
    font-weight: 700;

    display: flex;
    align-items: center;
}

#header-container #info {
    display: flex;
    flex-direction: column;
    height: 36px;
    justify-content: space-between;
    align-items: flex-start;

    margin: 0 36px;
}

button {
    cursor: pointer;
}

.disabled {
    pointer-events: none;
}

.nav-btn:visited {
    text-decoration: none;
}

#hamburger-menu-button-container {
    margin-left: auto;
    cursor: pointer;
}

#hamburger-menu-button-container img:hover {
    /* background-color: #E5EEF9; */
}

#navbar a {
    color: #231F20;
}

#navbar a.router-link-exact-active, #navbar .button-active {
    background-color: var(--button-yellow);
}

a.router-link-exact-active img {
    /* filter: invert(1) grayscale(1) brightness(2); */
}

a.router-link-exact-active:hover {
    /* background-color: var(--btn-blue-dark); */
}

.vue-recycle-scroller__item-view.hover:not(.selected) {
    /* background: white; */
}



/* Loading Bar CSS Animation */
/* https://csslayout.io/indeterminate-progress-bar/ */
/* ************************************************ */
.indeterminate-progress-bar {
    /* Color */
    background-color: #d1d5db;

    /* Size */
    height: 0.5rem;

    position: absolute;
    overflow: hidden;
    left: 0;

    width: 100%;
    z-index: 1000;
}

.indeterminate-progress-bar__progress {
    /* Color */
    background-color: #F9D54A;

    /* Rounded border */
    border-radius: 9999px;

    /* Absolute position */
    position: absolute;
    bottom: 0;
    top: 0;
    left: 0;
    width: 50%;

    /* Move the bar infinitely */
    animation-duration: 2s;
    animation-iteration-count: infinite;
    animation-name: indeterminate-progress-bar;
}

@keyframes indeterminate-progress-bar {
    from {
        left: -50%;
    }

    to {
        left: 100%;
    }
}
</style>
