<script setup>
import { store, state } from '@/store/store.js';
</script>


<template>
  <div v-if="!state.selectedCollaboration" style="width: 100%; height: 100%; display: flex; justify-content: center; align-items: center;">
    Please select a collaboration from the dropdown above.
  </div>
  <div v-else id="container">
    <div v-if="!filterCampaigns().length" style="width: 100%; height: 100%; display: flex; justify-content: center; align-items: center;">
      <p>No campaigns found that are associated with your selected collaboration.</p>
    </div>
    <div class="card" v-for="campaign, index in filterCampaigns()" :key="index" @click="selectCampaign(campaign)">
      <div class="card-content">
        <div class="campaign-title">{{ campaign[13].value }}</div>
        <div class="row">
          <img style="height: 24px; width: 24px; margin-right: 8px;" src="@/assets/document-text.png" alt="document-text"/>
          <div class="group">
            <span class="label">Posts</span>
            <span class="value">{{ campaign[125].value }}</span>
          </div>
          <!-- <img style="height: 24px; width: 24px; margin-right: 8px;" src="@/assets/wallet.png" alt="wallet"/>
          <div class="group">
            <span class="label">Total Payment</span>
            <span class="value">{{ state.toMoney(campaign[26].value) }}</span>
          </div> -->
        </div>
        <div class="row">
          <img style="height: 24px; width: 24px; margin-right: 8px;" src="@/assets/person.png" alt="person"/>
          <div class="group">
            <span class="label">Managers</span>
            <!-- <span class="value wt-400" v-for="manager of campaign[142].value" :key="manager.email">{{ manager.name }}</span> -->
             <!-- <span class="value wt-400" >{{ state.selectedCollaboration[58].value.name }}</span> -->
            <span class="value wt-400" v-if="campaign[148].value">{{ campaign[148].value.name }}</span>
          </div>
        </div>
        <div class="row">
          <img style="height: 24px; width: 24px; margin-right: 8px;" src="@/assets/link.svg" alt="link"/>
          <div class="group" style="overflow: hidden;">
            <span class="label">Creative Brief</span>
            <a :href="campaign[51].value" class="value link wt-400" style="z-index: 50;" @click="preventClickAction" target="_blank">{{ campaign[51].value }}</a>
          </div>
        </div>
      </div>
    </div>
    
    
  </div>
</template>

<script>
// @ is an alias to /src
// import HelloWorld from '@/components/HelloWorld.vue'

export default {
  name: 'Campaigns',
  components: {
    // HelloWorld
  },
  data() {
    return {
      filteredCampaigns: []
    }
  },
  methods: {
    filterCampaigns() {
      // If no collaboration is selected, display all campaigns (for now);
      if (!state.selectedCollaboration) return; // state.campaigns;
      // Only display campaigns related to the selected collaboration
      return state.campaigns.filter(campaign => campaign[36].value === state.selectedCollaboration[3].value);
    },
    selectCampaign(campaign) {
      state.selectedCampaign = campaign;
      this.$router.push({name: 'Campaign', params: {id: campaign[3].value }});
    },
    preventClickAction(event) {
      // event.preventDefault();
      event.stopPropagation();
      // You can add any additional logic here if needed
    }
  }
}
</script>

<style scoped>

.group .link {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  max-width: 100%;
}
.group .label-bold {
  font-size: 32px;
  font-weight: 700;
}
.group .label {
  font-size: 13px;
  padding-bottom: 6px;
}
.group .value {
  font-size: 14px;
  font-weight: 700;
  text-align: left;
} 
.row .group {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  flex: 1;
}
.card-content .row {
  display: flex;
  
}
.card-content {
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  width: 85%;
  height: 80%;
}
.card .campaign-title {
  font-weight: 700;
  font-size: 32px;
  align-self: flex-start;
  text-align: left;
}
.card {
  max-width: 388px;
  width: 388px;
  min-width: 280px;
  height: 280px;
  margin-bottom: 24px;

  display: flex;
  justify-content: center;
  align-items: center;

  border-radius: 24px;
  border: 2px solid #102A18;
  background-color: white;

  cursor: pointer;
}

#container {
  padding: 24px;

  /* please make a grid that is 3 columns wide, and will wrap around every 3rd card */
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  /* grid-auto-rows: 1fr; */
  grid-auto-rows: max-content;
  grid-gap: 24px;
  justify-items: center;
  /* align-items: center; */


  /* display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  flex-grow: revert; */

  overflow-y: auto;

}

</style>